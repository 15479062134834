<template lang="pug">

.about#about
    .about-item(v-for="item in about")
        img.img(:src="require('img/about/' + item.img)")
        .title {{ item.title }}
        .text {{ item.text }}
        .link(v-if="item.link_scroll" v-scroll-to="{ el: item.link, offset: -200 }") {{ item.link_text }}
        router-link.link(v-else :to="item.link") {{ item.link_text }}

</template>
<script>
export default {
    computed: {
        about() {
            return this.$store.getters['info/page']('home').about
        },
    },
}
</script>
<style lang="stylus" scoped>

.about
    // margin-top 130px
    margin-top 50px
    display flex
    justify-content space-between
    align-items flex-start
    // position relative
    // z-index 21
    +$tablet()
        margin-top 20px
    +$phone()
        flex-direction column
    &-item
        position relative
        width 350px
        padding 30px 30px 90px
        background-color $bg-choosen
        $small-shadow()
        border-radius 15px
        text-align center
        +$tablet()
            width 31%
            padding 20px 20px 70px
        +$phone()
            width 100%
            margin-top 20px

.img
    height 80px
    margin auto
    display block
    z-index 21
    position relative
    +$phone()
        height 60px

.title
    margin-top 15px
    font-size: 30px
    line-height: 37px
    color $can-push
    text-align center
    +$tablet()
        font-size 24px
        line-height 1.2

.text
    margin-top 25px
    font-size: 20px
    line-height: 26px
    color $can-choose
    text-align left
    +$tablet()
        font-size 17px
        line-height 1.2
    +$phone()
        text-align center

.link
    $btn()
    position absolute
    bottom 27px
    left 50%
    transform translateX(-50%)
    padding 0 13px
    font-size 18px
    line-height 1
    height 40px
    border-radius 10px
    white-space nowrap
    +$tablet()
        bottom 20px
        font-size 16px
        height 35px
</style>
