<template lang="pug">

.popup.auth
    .img
        g-icon(name="profile/profile")

    .toggle(v-if="query != 'verification'")
        .toggle-item(:class="{ active: query == 'login' }" @click="$store.commit('other/popup', { name: 'auth', query: 'login' })") Вход
        .toggle-item(:class="{ active: query == 'signup' }" @click="$store.commit('other/popup', { name: 'auth', query: 'signup' })") Регистрация

    v-login(v-if="query == 'login'")
    v-signup(v-else-if="query == 'signup'")

</template>
<script>
import vLogin from './login'
import vSignup from './signup'
export default {
    props: {
        query: String,
    },
    components: {
        vLogin,
        vSignup,
    },
}
</script>
<style lang="stylus" scoped>

.popup.auth
    padding 30px 35px 40px
    width 350px

.img
    width 105px
    height 105px
    border-radius 100%
    margin 0 auto 40px
    background-color $bg-choosen
    $small-shadow()
    display flex
    align-items center
    justify-content center
    .icon
        width 50px
        fill $green

.toggle
    position relative
    display flex
    height 38px
    &-item
        width 50%
        font-size 18px
        color $cant-be-choosen
        background-color $light-grey
        $small-shadow()
        display flex
        align-items center
        padding-left 16px
        padding-top 2px
        cursor pointer
        &:first-child
            border-top-left-radius 10px
            border-bottom-left-radius 10px
        &:last-child
            border-top-right-radius 10px
            border-bottom-right-radius 10px
        &.active
            background-color #fff
            color $green
            cursor default

.popup.auth
    >>>
        .message
            font-size 16px
            text-align center
            color $cant-be-choosen
            &.error
                color $red
        .form
            margin-top 20px
            display flex
            flex-direction column
            justify-content center
            align-items center

        .input, .input-phone
            width 100%
            height 45px
            background-color #fff
            box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.04), inset 0px 1px 2px rgba(0, 0, 0, 0.04)
            border-radius 10px
            font-size 18px
            color $can-push
            padding-left 16px
            border 1px solid transparent
            margin-top 9px
            transition all .1s
            position relative
            &.success
                box-shadow: inset 0px -1px 2px rgba(0, 140, 0, 0.2), inset 0px 1px 6px rgba(0, 140, 0, 0.3)
            &.error
                box-shadow: inset 0px -1px 2px rgba(180, 0, 0, 0.1), inset 0px 1px 6px rgba(180, 0, 0, 0.2)

            input
                height 100%
                width 100%
                padding 0
                border 0
                background-color transparent

                &::placeholder
                    color $cant-be-choosen

            .help
                position absolute
                // left 100%
                right 12px
                top 50%
                margin-top -11px
                // z-index 2
                padding-bottom 7px
                &:hover
                    .help-content
                        display flex
                &-btn
                    font-size 14px
                    background-color #f3f3f3
                    width 20px
                    height 20px
                    line-height 22px
                    border-radius 100%
                    text-align center
                    box-shadow 1px 1px 2px rgba(#000, .1)
                    cursor pointer
                &-content
                    z-index 3
                    display none
                    width 220px
                    position absolute
                    right 0
                    margin-top 7px
                    font-size 13px
                    background-color #fff
                    box-shadow 1px 1px 3px rgba(#000, .2)
                    border-radius 6px
                    padding 6px 10px

            &:first-child
                margin-top 0
            &.password:valid
                padding-top 7px
            &.invalid
                border-color $red
            &.input-code
                width 70px
                text-align center
                padding 0 9px

        .submit
            margin-top 30px
            height 46px
            width auto
            position relative
            border-radius 10px
            color $black
            $medium-shadow()
            $bordered()
            font-size 22px
            display flex
            align-items center
            cursor pointer
            &-login
                padding 0 33px
                font-size 24px
            &-signup
                padding 0 20px
            &-verification
                margin-top 20px
                padding 0 20px
</style>
